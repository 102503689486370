<template>
    <v-list v-if="journey != null" class="ma-0 pa-0 overflow-y-auto" dense>
        <v-list-item-group class="ma-0 pa-0">
          <v-list-group v-if="start != null && startIsMulti">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <span>{{ start.pickups.length }}</span>
                  <v-icon :class="[ start.pickups.every(y => courierMovements.some(z => z.id == y.id && z.releasedOn != null)) ? 'success--text' : 'accent--text' ]" small>mdi-map-marker-up</v-icon>
                  <strong class="ml-0">{{ start.location.locationName }}</strong>
                </v-list-item-subtitle>
                <v-list-item-subtitle>{{ start.location | toLocationLine }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-list-item v-if="isLengthyArray(start.pickups)" key="p" @click.stop="selectPickup(start.pickups)">
                <v-list-item-icon>
                    <v-icon>mdi-select-all</v-icon>
                </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Select All Pickups</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(pickup, ind) in start.pickups"
              :key="'p' + ind"
              @click.stop="selectPickup(pickup)">
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :class="courierMovements.some(c => c.id == pickup.id && c.releasedOn != null) ? 'success--text' : 'accent--text'" left>mdi-map-marker-up</v-icon>{{ pickup.buyer.companyName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="isLengthyArray(start.pickups)" />
            <v-list-item v-if="isLengthyArray(start.dropoffs)" key="d" @click.stop="selectDropoff(start.dropoffs)">
              <v-list-item-content>
                <v-list-item-subtitle class="text-center">Select All Dropoffs</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(dropoff, ind) in start.dropoffs"
              :key="'d' + ind"
              @click.stop="selectDropoff(dropoff)"
              dense>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :class="courierMovements.some(c => c.id == dropoff.id && c.deliveredOn != null) ? 'success--text' : 'accent--text'" left>mdi-map-marker-up</v-icon>{{ dropoff.buyer.companyName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="isLengthyArray(start.dropoffs)" />
          </v-list-group>
          <v-list-item v-else-if="start != null" @click.stop="selectPickup(start.pickups)">
            <v-list-item-content>
              <v-list-item-subtitle>
                <span>{{ start.pickups.length }}</span>
                <v-icon :class="[ start.pickups.every(y => courierMovements.some(z => z.id == y.id && z.releasedOn != null)) ? 'success--text' : 'accent--text' ]" small>mdi-map-marker-up</v-icon>
                <strong class="ml-0">{{ start.location.locationName }}</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ start.location | toLocationLine }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <drop-list
            class="destination-list"
            :items="filteredDestinations"
            @reorder="reorderDestinations">
            <template v-slot:item="{ item }">
              <drag :data="item" :key="item.id" :delay="500">
                <v-list-group v-if="(item.pickups.length + item.dropoffs.length) > 1" :append-icon="null">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span>{{ item.pickups.length }}</span>
                        <v-icon :class="[ item.pickups.every(y => courierMovements.some(z => z.id == y.id && z.releasedOn != null)) ? 'success--text' : 'accent--text' ]" small>mdi-map-marker-up</v-icon>
                        <strong class="ml-0">{{ item.location.locationName }}</strong>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.location | toLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <v-list-item v-if="isLengthyArray(item.pickups)" key="p" @click.stop="selectPickup(item.pickups)" dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Pickups - Select All</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="(pickup, ind) in item.pickups"
                    :key="'p' + ind"
                    @click.stop="selectPickup(pickup)"
                    dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon :class="courierMovements.some(c => c.id == pickup.id && c.releasedOn != null) ? 'success--text' : 'accent--text'" left>mdi-map-marker-up</v-icon>{{ pickup.buyer.companyName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="isLengthyArray(item.pickups)" />
                  <v-list-item v-if="isLengthyArray(item.dropoffs)" key="d" @click.stop="selectDropoff(item.dropoffs)" dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Dropoffs - Select All</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="(dropoff, ind) in item.dropoffs"
                    :key="'d' + ind"
                   
                    @click.stop="selectDropoff(dropoff)"
                    dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon :class="courierMovements.some(c => c.id == dropoff.id && c.deliveredOn != null) ? 'success--text' : 'accent--text'" left>mdi-map-marker-up</v-icon>{{ dropoff.buyer.companyName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="isLengthyArray(item.dropoffs)" />
                </v-list-group>
                <v-list-item v-else @click.stop="selectDestItem(item)">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                        <span v-if="item.pickups.length > 0">{{ item.pickups.length }}</span>
                        <v-icon v-if="item.pickups.length > 0" class="mr-0" :class="[ item.pickups.every(y => courierMovements.some(z => z.id == y.id && z.releasedOn != null)) ? 'success--text' : 'accent--text' ]" small>mdi-map-marker-up</v-icon>
                        <span v-if="item.dropoffs.length > 0">{{ item.dropoffs.length }}</span>
                        <v-icon v-if="item.dropoffs.length > 0" :class="[ item.dropoffs.every(y => courierMovements.some(z => z.id == y.id && z.deliveredOn != null)) ? 'success--text' : 'accent--text' ]" small>mdi-map-marker-down</v-icon>
                      <strong class="ml-0">{{ item.location.companyAccount.companyName }}</strong>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.location | toLocationLine }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </drag>
            </template>
            <template v-slot:inserting-drag-image="{ data }">
              <div>{{ data }}</div>
            </template>
            <template v-slot:reordering-drag-image />
            <template v-slot:feedback="{ data }">
              <v-skeleton-loader
                type="list-item-avatar-three-line"
                :key="data.id" />
            </template>
          </drop-list>
          <v-list-group v-if="end != null && endIsMulti" :append-icon="null">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <span>{{ end.dropoffs.length }}</span>
                  <v-icon :class="end.dropoffs.every(y => courierMovements.some(z => z.id == y.id && z.deliveredOn != null)) ? 'success--text' : 'accent--text'" small>mdi-map-marker-down</v-icon>
                  <strong class="ml-0">{{ end.location.locationName }}</strong>
                </v-list-item-subtitle>
                <v-list-item-subtitle>{{ end.location | toLocationLine }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-list-item v-if="isLengthyArray(end.pickups)" key="p" @click.stop="selectPickup(end.pickups)" dense>
              <v-list-item-content>
                <v-list-item-subtitle>Pickups - Select All</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(pickup, ind) in end.pickups"
              :key="'p' + ind"
             
              @click.stop="selectPickup(pickup)"
              dense>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :class="courierMovements.some(c => c.id == pickup.id && c.releasedOn != null) ? 'success--text' : 'accent--text'" left>mdi-map-marker-down</v-icon>{{ pickup.buyer.companyName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="isLengthyArray(end.pickups)" />
            <v-list-item v-if="isLengthyArray(end.dropoffs)" 
              key="d"
              @click.stop="selectDropoff(end.dropoffs)"
             
              dense>
              <v-list-item-content>
                <v-list-item-subtitle>Dropoffs - Select All</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(dropoff, ind) in end.dropoffs"
              :key="'d' + ind"
             
              @click.stop="selectDropoff(dropoff)"
              dense>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon :class="courierMovements.some(c => c.id == dropoff.id && c.deliveredOn != null) ? 'success--text' : 'accent--text'" left>mdi-map-marker-down</v-icon>{{ dropoff.buyer.companyName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="isLengthyArray(end.dropoffs)" />
          </v-list-group>
          <v-list-item v-else-if="end != null" @click.stop="selectDropoff(end.dropoffs)">
            <v-list-item-content>
              <v-list-item-subtitle>
                <span>{{ end.dropoffs.length }}</span>
                <v-icon :class="[ end.dropoffs.every(y => courierMovements.some(z => z.id == y.id && z.deliveredOn != null)) ? 'success--text' : 'accent--text' ]" small>mdi-map-marker-down</v-icon>
                <strong class="ml-0">{{ end.location.locationName }}</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ end.location | toLocationLine }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
    </v-list>
  </template>
  
  <script>
  import { Drag, DropList } from "vue-easy-dnd";
  import { firstBy } from 'thenby';
  
  export default {
    name: "Journey-Destination-List",
    components: {
      Drag,
      DropList,
    },
    data: function () {
      return {
        currentMovementIndex: 0,
        currentDestinationIndex: 0,
        end: null,
        filteredDestinations: [],
        start: null
      };
    },
    props: {
      journey: null,
    //   nextToggle: {
    //     type: Boolean,
    //     default: false
    //   },
    //   previousToggle: {
    //     type: Boolean,
    //     default: false
    //   },
      refreshToggle: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      courierMovements() {
        return this.journey?.movements ?? [];
      },
      endIsMulti() {
        return this.end != null && (this.end.pickups.length + this.end.dropoffs.length) > 1;
      },
      startIsMulti() {
        return this.start != null && (this.start.pickups.length + this.start.dropoffs.length) > 1;
      }
    },
    watch: {
      journey() {
        console.log('j');
        this.refresh();
      },
    //   nextToggle: function() {
    //     if (this.currentDestinationIndex + 1 < this.filteredDestinations.length) {
    //         this.selectDestination(this.currentDestinationIndex + 1);
    //     }
    //   },
    //   previousToggle: function() {
    //     if (this.currentDestinationIndex > 0) {
    //         this.selectDestination(this.currentDestinationIndex - 1);
    //     }
    //   },
      refreshToggle() {
        this.refresh();
      },
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            if (this.journey == null) {
                this.start = null;
                this.end = null;
                this.filteredDestinations = null;
                this.filteredMovements = null;
            }
            else {
                //this.filteredDestinations = [];
        
                //add start and end
                this.start = {
                    id: "start",
                    location: this.journey.startLocation,
                    pickups: [],
                    dropoffs: [],
                };
        
                this.end = {
                    id: "end",
                    location: this.journey.endLocation,
                    pickups: [],
                    dropoffs: [],
                };
        
                var dList = [];
                //load departures first
                this.journey.movements.forEach(movement => {
                    var pickup = {
                        id: "dep" + movement.id,
                        location: movement.departureLocation,
                        sortNumber: movement.departureSortNumber,
                        pickups: [movement],
                        dropoffs: [],
                        originalIndex: 0,
                    };
                    
                    var dropoff = {
                        id: "dest" + movement.id,
                        location: movement.destinationLocation,
                        sortNumber: movement.arrivalSortNumber,
                        pickups: [],
                        dropoffs: [movement],
                        originalIndex: 0,
                    };
        
                    if (movement.departureSortNumber <= 1 && this.start.location.id == movement.departureLocationID) {
                        this.start.pickups.push(movement);
                    }
                    else {
                        var dest = dList.find(x => x.sortNumber == movement.departureSortNumber);
                        if (dest != null) {
                            if (dest.location.id == movement.departureLocationID) {
                                //add to existing destination
                                dest.pickups.push(movement);
                            }
                            else {
                                //insert as different destination but same sort number
                                dList.splice(dList.findIndex(x => x.sortNumber == movement.departureSortNumber), 0, pickup);
                            }
                        }
                        else {
                            //push to end of list
                            dList.push(pickup);
                        }
                    }
        
                    if (movement.destinationLocationID == this.end.location.id) {
                        this.end.dropoffs.push(movement);
                    }
                    else {
                        var drest = dList.find(x => x.sortNumber == movement.arrivalSortNumber);
                        if (drest != null) {
                            if (drest.location.id == movement.destinationLocationID) {
                                drest.dropoffs.push(movement);
                            }
                            else {
                                dList.splice(dList.findIndex(x => x.sortNumber == movement.arrivalSortNumber), 0, dropoff);
                            }
                        }
                        else {
                            dList.push(dropoff);
                        }
                    }
                })
                
                dList.sort(firstBy(x => x.sortNumber));
        
                for (let i = 0; i < dList.length; i++) {
                    dList[i].originalIndex = (i + 1);
                }
        
                this.filteredDestinations = dList;
            }
        },
        reorderDestinations(event) {
            var m = this.filteredDestinations[event.from];
            var doReorder = true;
    
            if (event.to > event.from) {
            //make sure no prior indexes have dropoffs matching these pickups
            for (let i = 0; i <= event.to; i++) {
                const testMove = this.filteredDestinations[i];
                if (testMove.id != m.id && testMove.dropoffs.some((dr) => m.pickups.some((y) => y.id == dr.id))) {
                doReorder = false;
                }
            }
            } 
            else 
            {
            //make sure no post indexes are pickups after dropoffs
            for (let ii = event.to; ii < this.filteredDestinations.length; ii++) {
                const testMove = this.filteredDestinations[ii];
                if (testMove.id != m.id && testMove.pickups.some((pi) => m.dropoffs.some((y) => y.id == pi.id))) {
                doReorder = false;
                }
            }
            }
    
            if (doReorder) {
            event.apply(this.filteredDestinations);
            for (let iv = 0; iv < this.filteredDestinations.length; iv++) {
                var dest = this.filteredDestinations[iv];
                dest.pickups.forEach((element) => {
                element.departureSortNumber = iv + 1;
                });
                dest.dropoffs.forEach((element) => {
                element.arrivalSortNumber = iv + 1;
                });
            }
            this.$emit('resorted');
            }
        },
        selectDestItem(item) {
            if (item.dropoffs.length == 1) {
                this.selectDropoff(item.dropoffs);
            }
            else if (item.pickups.length > 0) {
                this.selectPickup(item.pickups);
            }
        },
        selectDropoff(dropoff) {
            var mList = [];
            if (Array.isArray(dropoff)) {
                dropoff.forEach(d => {
                    mList.push({ movement: d, isPickup: false });
                })
            }
            else {
                mList.push({ movement: dropoff, isPickup: false });
            }
    
            this.$emit('change', mList);
        },
        selectPickup(pickup) {
            var mList = [];
            if (Array.isArray(pickup)) {
                pickup.forEach(p => {
                    mList.push({ movement: p, isPickup: true });
                })
            }
            else {
                mList.push({ movement: pickup, isPickup: true });
            }
    
            this.$emit('change', mList);
        },
        // selectDestination(index) {
        //     if (index == null || index == undefined || index >= (this.filteredDestinations.length + 2)) {
        //         this.$emit('change', []);
        //         return;
        //     }
    
        //     this.currentDestinationIndex = index;
        //     var dest = null;
        //     if (index == 0) {
        //         dest = this.start;
        //     }
        //     else if (index == this.filteredDestinations.length + 1) {
        //         dest = this.end;
        //     }
        //     else {
        //         dest = this.filteredDestinations.find(y => y.originalIndex == index);
        //     }
    
        //     var movementList = [];
        //     for (let i = 0; i < dest.pickups.length; i++) {
        //     const pickup = dest.pickups[i];
        //     movementList.push({ movement: pickup, isPickup: true });
        //     }
    
        //     if (movementList.length > 0) {
        //     if (isSubGroup) {
        //         this.$emit('change', movementList);
        //     }
        //     return;
        //     }
    
        //     for (let ii = 0; ii < dest.dropoffs.length; ii++) {
        //     const dropoff = dest.dropoffs[ii];
        //     movementList.push({ movement: dropoff, isPickup: false });
        //     }
    
        //     if (movementList.length == 1) {
        //     this.$emit('change', movementList);
        //     }
        // },
        unpackedDestDrag(openDest) {
            this.filteredMovements.forEach(dest => {
                if (dest.movement.id == openDest.movement.id) {
                    dest.isMoving = true;
                }
            })
        }
    }
  };
  </script>